export const ACCESS_KEY_TYPES = {
	API_KEY: 1,
	COOKIE_ACCESS_KEY: 2,
	PREFERENCE_WIDGET_ACCESS_KEY: 3
}

export const API_IDS = {
	SAPI: 1,
	TAPI: 2,
	STANDARDAPI: 3
}
