<template>
	<ViewLayout>
		<template #header-title>
			Access Keys
		</template>
		<template #header-caption>
			Add, edit and manage access keys
		</template>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<ApiTab
						:access-key-type="ACCESS_KEY_TYPES.API_KEY"
						:access-keys="apiAccessKeys"
						@create-access-key="createAccessKey"
						@edit-access-key="editAccessKey"
						@load-access-keys="getAllAccessKeys"
					/>
				</template>
				<template #1>
					<CookieTab
						:access-key-type="ACCESS_KEY_TYPES.COOKIE_ACCESS_KEY"
						:access-keys="cookieAccessKeys"
						@create-access-key="createAccessKey"
						@edit-access-key="editAccessKey"
						@load-access-keys="getAllAccessKeys"
					/>
				</template>
				<template #2>
					<PreferenceWidgetTab
						:access-key-type="ACCESS_KEY_TYPES.PREFERENCE_WIDGET_ACCESS_KEY"
						:access-keys="preferenceWidgetAccessKeys"
						@create-access-key="createAccessKey"
						@edit-access-key="editAccessKey"
						@load-access-keys="getAllAccessKeys"
					/>
				</template>
			</TabItems>
		</template>
	</ViewLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import Tabs from '../../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../../shared/components/tab-items.vue'
import ApiTab from './api-tab.vue'
import CookieTab from './cookie-tab.vue'
import PreferenceWidgetTab from './preference-widget-tab.vue'
import { MANAGE_ACCESS_KEY } from '../../../../router/route-names.js'
import { getPreferenceWidgetAccessKeys, getConsentBannerAccessKeys, getApiKeys } from '../../../../../../shared/utils/api/access-keys.js'
import { ACCESS_KEY_TYPES } from '../../../../../../shared/enums/access-keys.js'
import { SEE_PREF_WIDGETS_AREA, CAN_SEE_PROGRESSIVE_PROFILING, SEE_COOKIE_MODULE_AREA } from '../../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: {
		ViewLayout,
		Tabs,
		TabItems,
		ApiTab,
		CookieTab,
		PreferenceWidgetTab
	},
	props: {
		accessedVia: Number
	},
	data () {
		return {
			ACCESS_KEY_TYPES,
			tabs: [{ title: 'API', slot: '0' }],
			tab: '0',
			apiAccessKeys: [],
			cookieAccessKeys: [],
			preferenceWidgetAccessKeys: []
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission'])
	},
	async created () {
		this.getAllAccessKeys()
		this.populateOptionalTabs()
		if (this.accessedVia === ACCESS_KEY_TYPES.COOKIE_ACCESS_KEY) {
			this.tab = '1'
		} else if (this.accessedVia === ACCESS_KEY_TYPES.PREFERENCE_WIDGET_ACCESS_KEY) {
			this.tab = '2'
		} else {
			this.tab = '0'
		}
	},
	methods: {
		async getAllAccessKeys () {
			await this.getPreferenceWidgetAccessKeys()
			await this.getCookieAccessKeys()
			await this.getApiAccessKeys()
		},
		async getPreferenceWidgetAccessKeys () {
			const { preferenceWidgetAccessKeys } = await getPreferenceWidgetAccessKeys()
			this.preferenceWidgetAccessKeys = preferenceWidgetAccessKeys
		},
		async getCookieAccessKeys () {
			const { cookieAccessKeys } = await getConsentBannerAccessKeys()
			this.cookieAccessKeys = cookieAccessKeys
		},
		async getApiAccessKeys () {
			const { apiKeys } = await getApiKeys()
			this.apiAccessKeys = apiKeys
		},
		createAccessKey (accessKeyType) {
			this.$router.push({
				name: MANAGE_ACCESS_KEY,
				query: {
					accessKeyType
				}
			})
		},
		editAccessKey (accessKey) {
			const accessKeyType = accessKey.accessKeyType
			const accessKeyToEdit = accessKey.accessKeyToEdit
			this.$router.push({
				name: MANAGE_ACCESS_KEY,
				params: {
					accessKeyToEdit
				},
				query: {
					accessKeyType
				}
			})
		},
		populateOptionalTabs () {
			if (this.productAreaPermission(SEE_COOKIE_MODULE_AREA)) {
				this.tabs.push({ title: 'Cookie', slot: '1' })
			}
			if (this.productAreaPermission(SEE_PREF_WIDGETS_AREA) || this.productAreaPermission(CAN_SEE_PROGRESSIVE_PROFILING)) {
				this.tabs.push({ title: 'Preference Widget', slot: '2' })
			}
		}
	}
}
</script>
